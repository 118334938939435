<template>
    <v-container
        fluid
    >
        <v-row>
            <v-col
                class="text-center"
            >
                <v-icon
                    size="100"
                >
                    mdi-check-circle
                </v-icon>
                <div class="text-center">
                    <h1>{{ $t('customerportal-addCreditCard-added') }}</h1>
                    <span>{{ $t('customerportal-addCreditCard-thanks') }}</span>
                </div>
                <v-btn
                    class="my-2"
                    @click="close"
                >
                    {{ $t('customerportal-addCreditCard-close') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    data() {
        return {
        }
    },
    computed: {
    },
    created() {        
    },    
    methods: {
        close() {
            window.close()
        }
    }
}
</script>
